export interface AppCommonNote {
  id: string;
  user_id: string;
  organization_id: string;
  content: {
    quality_of_sleep: string;
    quality_of_diet: string;
    observed_pains: string;
    tiredness: string;
    description: string;
  };
  created_at: string;
}

export class AppCommonNote implements AppCommonNote {
  id: string;
  user_id: string;
  organization_id: string;
  content: {
    quality_of_sleep: string;
    quality_of_diet: string;
    observed_pains: string;
    tiredness: string;
    description: string;
  };
  created_at: string;

  constructor() {
    this.id = "";
    this.user_id = "";
    this.organization_id = "";
    this.content = {
      quality_of_sleep: "",
      quality_of_diet: "",
      observed_pains: "",
      tiredness: "",
      description: "",
    };
    this.created_at = "";
  }
}