
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Vendors
import moment from "moment";

// Models
import {AdministratorPlayersProfileNotesListIndex} from "@/models/app/administrator/players/profile/notes/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorPlayersProfileNotesListFormLookupIndexVue from "@/views/app/administrator/players/profile/notes/list/form/lookup.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorPlayersProfileNotesListFormLookupIndexVue,
  },
})
export default class AdministratorPlayersProfileNotesListIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      user_id: "*",
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  notes: Array<AdministratorPlayersProfileNotesListIndex> = [];

  async getNotes(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/notes`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.notes = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    this.formData.filter.user_id = this.$route.params.id;
    await this.getNotes();
  }
}
