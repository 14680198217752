
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppCommonNote} from "@/models/app/common/note";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";
import {Search} from "@element-plus/icons-vue";

@Options({
  props: [
    "note",
  ],
  components: {
    ElDialog,
    ElButton,
    Search,
  },
})
export default class AdministratorPlayersProfileNotesListFormLookupIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  note: AppCommonNote = new AppCommonNote();
}
