
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorPlayersProfileNavigationVue from "@/components/administrator/players/profile/navigation.vue";
import AdministratorPlayersProfileNotesListIndexVue from "@/views/app/administrator/players/profile/notes/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorPlayersProfileNavigationVue,
    AdministratorPlayersProfileNotesListIndexVue
  },
})
export default class AdministratorPlayersProfileNotesIndexVue extends Vue {
}
